import { InstagramOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import colors from 'helpers/colors'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import FacebookIcon from 'public/icons/facebook.svg'
import XIcon from 'public/icons/x.svg'
import { VFC } from 'react'
import styled from 'styled-components'

type Props = {
  onlyLogo: boolean
}

export const Footer: VFC<Props> = ({ onlyLogo }) => {
  const { xs } = useBreakpoint()
  const { t } = useTranslation()

  if (onlyLogo) {
    return (
      <div
        style={{
          backgroundColor: colors.black,
          marginTop: '80px',
        }}
      >
        <div
          style={{
            padding: xs ? '32px 16px' : '32px 64px',
            display: 'flex',
            alignItems: 'center',
            gap: xs ? '0px' : '64px',
            maxWidth: '1280px',
            margin: '0 auto',
            overflowX: 'scroll',
          }}
        >
          <div
            style={{
              flex: xs ? 1 : 'inherit',
              gap: xs ? '12px' : '10px',
              display: 'flex',
              flexDirection: xs ? 'column' : 'row',
              alignItems: xs ? 'flex-start' : 'flex-end',
            }}
          >
            <div style={{ marginRight: '10px' }}>
              <Image src={'/logo-white.svg'} height={24} width={70} />
            </div>
            <StyledTypography type="secondary" style={{ fontSize: '10px' }}>
              © ︎ArtX inc. all rights reserved.
            </StyledTypography>
          </div>
          <div
            style={{
              flex: xs ? 1 : 'inherit',
              gap: xs ? '12px' : '24px',
              display: 'flex',
              flexDirection: xs ? 'column' : 'row',
              alignItems: xs ? 'flex-start' : 'flex-end',
            }}
          >
            <StyledAnchor
              target={'_blank'}
              href="https://cloud.artx.com/terms"
              style={{ fontSize: '12px' }}
            >
              {t('利用規約')}
            </StyledAnchor>
            <StyledAnchor
              target={'_blank'}
              href="https://cloud.artx.com/privacy"
              style={{ fontSize: '12px' }}
            >
              {t('プライバシーポリシー')}
            </StyledAnchor>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        backgroundColor: colors.black,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: xs ? 'column' : 'row',
          justifyContent: 'space-between',
          gap: xs ? 32 : 12,
          padding: xs ? '48px 16px' : '40px 64px',
          maxWidth: '1280px',
          margin: '0 auto',
          overflowX: 'scroll',
        }}
      >
        <div style={{ minWidth: '285px' }}>
          <div
            style={{
              gap: xs ? '0px' : '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <div style={{ marginRight: '10px' }}>
              <Image src={'/logo-white.svg'} height={24} width={65} />
            </div>
            <StyledTypography
              type="secondary"
              style={{ fontSize: '10px', marginBottom: 2 }}
            >
              © ︎ArtX inc. all rights reserved.
            </StyledTypography>
          </div>
        </div>
        <div
          style={{
            flex: 1,
            rowGap: '12px',
            display: 'flex',
            gap: xs ? '40px' : '0px',
          }}
        >
          <div
            style={{
              width: '285px',
              rowGap: '12px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <StyledTypography strong style={{ fontSize: '14px' }}>
              {t('私たちについて')}
            </StyledTypography>
            <StyledAnchor
              target={'_blank'}
              rel="noopener noreferrer"
              href="https://cloud.artx.com/ja/company"
              style={{ fontSize: '12px' }}
            >
              {t('運営会社')}
            </StyledAnchor>
            <StyledAnchor
              target={'_blank'}
              rel="noopener noreferrer"
              href="https://cloud.artx.com/terms"
              style={{ fontSize: '12px' }}
            >
              {t('利用規約')}
            </StyledAnchor>
            <StyledAnchor
              target={'_blank'}
              rel="noopener noreferrer"
              href="https://cloud.artx.com/privacy"
              style={{ fontSize: '12px' }}
            >
              {t('プライバシーポリシー')}
            </StyledAnchor>
            <StyledAnchor
              target={'_blank'}
              rel="noopener noreferrer"
              href="https://cloud.artx.com/ja/cookie-policy"
              style={{ fontSize: '12px' }}
            >
              {t('クッキーポリシー')}
            </StyledAnchor>
            <StyledAnchor
              target={'_blank'}
              rel="noopener noreferrer"
              href="/law"
              style={{ fontSize: '12px' }}
            >
              {t('特定商取引法に基づく表記')}
            </StyledAnchor>
          </div>
          <div
            style={{
              width: '285px',
              rowGap: '12px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <StyledTypography strong style={{ fontSize: '14px' }}>
              {t('サービス')}
            </StyledTypography>
            <StyledAnchor
              target={'_blank'}
              href="/"
              style={{ fontSize: '12px' }}
            >
              ArtX
            </StyledAnchor>
            <StyledAnchor
              target={'_blank'}
              href="https://cloud.artx.com"
              style={{ fontSize: '12px' }}
            >
              ArtXCloud
            </StyledAnchor>
          </div>
        </div>
        <div
          style={{
            width: '285px',
            rowGap: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: xs ? 'flex-start' : 'flex-end',
          }}
        >
          <StyledIconsWrapper style={{ display: 'flex', gridGap: '24px' }}>
            <a
              href="https://www.instagram.com/theartxcollective/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramOutlined style={{ width: '25px', height: '25px' }} />
            </a>
            <a
              href="https://www.facebook.com/theartxofficial"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon style={{ width: '25px', height: '25px' }} />
            </a>
            <a
              href="https://twitter.com/theArtXofficial"
              target="_blank"
              rel="noreferrer"
            >
              <XIcon style={{ width: '20px', height: '22px' }} />
            </a>
          </StyledIconsWrapper>
        </div>
      </div>
    </div>
  )
}

const StyledTypography = styled(Typography.Text)`
  &.ant-typography {
    color: #fff;
  }
`

const StyledAnchor = styled.a`
  color: white;
  :hover {
    color: white;
    opacity: 0.8;
  }
`

const StyledIconsWrapper = styled.div`
  img,
  svg {
    cursor: pointer;
    color: white;
    height: 25px;
    width: 25px;
    :hover {
      opacity: 0.8;
    }
  }
`
